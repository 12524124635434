@font-face {
  font-family: HarmonyOS_Sans_Medium;
  src: url("HarmonyOS_Sans_Medium.16eb378e.ttf");
}

@font-face {
  font-family: HarmonyOS_Sans_Regular;
  src: url("HarmonyOS_Sans_Regular.8b051e47.ttf");
}

* {
  box-sizing: border-box;
  font-family: HarmonyOS_Sans_Medium;
}

html {
  font-style: normal;
  font-weight: 400;
}

body {
  color: #fff;
  background: #000;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 36px;
}

.content-retract {
  padding: 0 32px;
}

.h50 {
  height: 50px;
}

.h80 {
  height: 80px;
}

.mb64 {
  margin-bottom: 64px;
}

.common_btns {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background: #3671ee;
  border-radius: 26px;
  width: 240px;
  height: 48px;
  margin: auto;
  padding: 0 16px;
  line-height: 48px;
}

.common_btns:hover {
  opacity: .4;
}

.common_btns a {
  color: #fff;
  text-decoration: none;
}

.header {
  z-index: 10;
  border-bottom: .5px solid gray;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 36px;
  display: flex;
}

.header .links {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.header .links .left {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.header .links .medium, .header .links .github, .header .links .twitler {
  color: #fff;
  background-position: 0;
  background-repeat: no-repeat;
  margin-right: 32px;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  text-decoration: none;
  transition: background-color .6s, color .4s;
}

.header .links .medium {
  cursor: pointer;
  border-radius: 12px;
  padding: 4px 12px;
}

.header .links .medium:hover {
  color: #3671ee;
}

.header .links .chatbox {
  cursor: pointer;
  position: relative;
}

.header .links .chatbox svg {
  -webkit-transition: background-color .6s ease, color .4s ease transform 1s ease-in-out;
  transition: background-color .6s ease, color .4s ease transform 1s ease-in-out;
  margin-left: 8px;
  transform: rotate(0);
}

.header .links .chatbox .chatbox-con {
  display: none;
}

.header .links .chatbox:hover .chatbox-con {
  z-index: 1;
  border-top: 4px solid #0000;
  width: 100%;
  padding-top: 20px;
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
}

.header .links .chatbox:hover .chatbox-con .chatbox-panel {
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  overflow: hidden;
}

.header .links .chatbox:hover .chatbox-con .chatbox-panel .chatbox-item {
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  width: 100%;
  padding: 4px;
  font-size: 15px;
  display: flex;
  overflow: hidden;
}

.header .links .chatbox:hover .chatbox-con .chatbox-panel .chatbox-item:last-of-type {
  border-bottom: 0;
}

.header .links .chatbox:hover .chatbox-con .chatbox-panel .chatbox-item a {
  color: #333;
  padding-left: 12px;
  text-decoration: none;
  display: block;
}

.header .links .chatbox:hover .chatbox-con .chatbox-panel .chatbox-item:hover {
  background-color: #eee;
}

.header .links .twitler {
  width: 24px;
  height: 24px;
}

.header .links .twitler:hover {
  color: #3671ee;
}

.header .links .github {
  background-size: 19px;
  width: 24px;
  height: 24px;
  margin-right: 28px;
}

.header .links .github:hover {
  color: #3671ee;
}

.header .links .right {
  cursor: pointer;
  color: #fff;
  align-items: center;
  display: flex;
}

.header .links .right .icon-label {
  margin-left: 8px;
  transition: background-color .6s, color .4s;
}

.header .links .right:hover .icon-label {
  color: #3671ee;
}

.top-connect {
  display: inline-block;
}

.top-connect .hidden {
  display: none !important;
}

.top-connect .connect-btn {
  cursor: pointer;
  background: #3671ee;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  min-width: 158px;
  height: 38px;
  padding: 0 12px;
  transition: opacity .5s;
  display: flex;
}

.top-connect .connect-btn .btn-label {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.top-connect .connect-btn svg {
  height: 20px;
  margin-right: 6px;
}

.top-connect .connect-btn:hover {
  opacity: .8;
}

.top-connect .has-connect-btn {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 12px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 6px;
  min-width: 158px;
  height: 38px;
  padding: 0 12px;
  display: inline-flex;
  position: relative;
}

.top-connect .has-connect-btn .logout-con {
  display: none;
}

.top-connect .has-connect-btn:hover .logout-con {
  border-top: 4px solid #0000;
  display: block;
  position: absolute;
  top: 38px;
  left: 0;
}

.top-connect .has-connect-btn:hover .logout-con .logout {
  cursor: pointer;
  color: #fff;
  text-align: right;
  background: #202124;
  border: 1px solid #404447;
  border-radius: 12px;
  align-self: stretch;
  align-items: center;
  width: 158px;
  height: 48px;
  padding: 0 16px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.top-connect .has-connect-btn:hover svg {
  transform: scaleY(-1);
}

.top-connect .has-connect-btn .address {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.top-connect .has-connect-btn svg {
  width: 12px;
  height: 12px;
}

.panel-one-new {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.panel-one-new .left {
  position: relative;
}

.panel-one-new .left img {
  width: 585px;
  height: 74px;
  margin-bottom: 52px;
}

.panel-one-new .left .label {
  color: #fff;
  font-family: HarmonyOS_Sans_Regular;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.panel-one-new .left .label a {
  color: var(--GroupFi-100, #3671ee);
  text-decoration: none;
}

.panel-one-new .left .group-btns {
  align-items: center;
  gap: 28px;
  margin-top: 56px;
  display: flex;
}

.panel-one-new .left .group-btns .btn {
  background: var(--GroupFi-100, #3671ee);
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 184px;
  height: 48px;
  padding: 0 12px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.panel-one-new .left .group-btns .btn:hover {
  background: var(--GroupFi-80, #3671eecc);
}

.panel-one-new .left .group-btns .line-btn {
  border: 2px solid #fff;
  background: none !important;
}

.panel-one-new .left .group-btns .line-btn:hover {
  color: #3671ee;
  border-color: #3671ee;
}

.panel-one-new .left .guide-step-1 {
  background: var(--, #000);
  border: 1.5px solid #fff;
  border-radius: 12px;
  align-items: center;
  min-width: 393px;
  margin-top: 24px;
  padding: 12px 16px;
  display: flex;
  position: absolute;
  bottom: -94px;
}

@keyframes guide-point-ani {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.panel-one-new .left .guide-step-1 .guide-point {
  width: 23px;
  height: auto;
  margin-bottom: 0;
  margin-right: 16px;
  animation: 1s linear infinite guide-point-ani;
}

.panel-one-new .left .guide-step-1 .guide-step-label {
  color: #fff;
  flex: 1;
  margin-right: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.panel-one-new .left .guide-step-1 .guide-step-label span {
  color: var(--GroupFi-100, #3671ee);
  cursor: pointer;
  text-decoration-line: underline;
}

.panel-one-new .left .guide-step-1 .guide-close {
  cursor: pointer;
  margin-bottom: 24px;
}

.panel-one-new .right img {
  width: 570px;
  height: 556px;
}

.panel-one {
  text-align: center;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.panel-one .title {
  max-width: 650px;
}

.panel-one .hover {
  color: #3671ee;
  opacity: .3;
  cursor: pointer;
}

.panel-one .left_img {
  width: 100%;
}

.panel-one .left-img-con {
  z-index: -1;
  width: 416px;
  animation-duration: .8s;
  animation-delay: .8s;
  position: absolute;
  top: -10px;
  left: -100px;
}

.panel-one .right_img {
  width: 100%;
}

.panel-one .right-img-con {
  z-index: -1;
  width: 380px;
  animation-duration: .8s;
  animation-delay: 1.6s;
  position: absolute;
  top: -15px;
  right: -70px;
}

.panel-one .sub_title {
  letter-spacing: 0;
  text-align: center;
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
}

.panel-two {
  color: var(--Color-Background-White, #fff);
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 104px;
  font-style: normal;
}

.panel-two .pannel-two-item {
  width: 373px;
}

.panel-two .pannel-two-item .pannel-two-item-title {
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.panel-two .pannel-two-item .panel-two-item-desc {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.panel-two .pannel-two-item-desc-1 {
  color: var(--Function-text-tertiary, #9aadce);
  text-align: right;
  width: 1026px;
  margin: 20px auto 0;
  font-size: 10px;
  line-height: 28px;
}

.panel-three {
  width: 1200px;
  margin: 0 auto;
}

.panel-three-img {
  width: 513.7px;
  height: 540px;
}

.panel-three .panel-three-right {
  color: var(--Color-Background-White, #fff);
  width: 620px;
  font-style: normal;
}

.panel-three-sub-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.panel-three-title {
  letter-spacing: -.88px;
  margin: 32px 0;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.panel-three-desc-1 {
  letter-spacing: -.56px;
  color: var(--Function-text-tertiary, #9aadce);
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.panel-three-desc-2 {
  margin-bottom: 16px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.panel-three-desc-2:before {
  content: " ";
  background-image: url("pannel-three-icon.70298b82.png");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 0;
}

.panel-three-desc-2 i {
  color: var(--Function-text-tertiary, #9aadce);
}

.panel-three-desc-3 {
  color: var(--Function-text-tertiary, #9aadce);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.panel-four {
  width: 1200px;
  margin: 125px auto 0;
}

.panel-four-img {
  width: 540px;
  height: 546px;
}

.panel-four-left {
  color: var(--Color-Background-White, #fff);
  width: 620px;
  font-style: normal;
}

.panel-four-title {
  letter-spacing: -.88px;
  margin-top: 40px;
  margin-bottom: 32px;
  font-size: 44px;
  font-weight: 700;
  line-height: 56px;
}

.panel-four-item {
  margin-bottom: 40px;
  padding-left: 72px;
  position: relative;
}

.panel-four-item-title {
  letter-spacing: -.56px;
  color: var(--Function-text-tertiary, #9aadce);
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.panel-four-item-desc {
  margin-top: 4px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.panel-four-item:before {
  content: "1";
  color: #000;
  background: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.panel-four-item-1:before {
  content: "1";
}

.panel-four-item-1 i {
  border-left: 1px dotted #fff;
  width: 1px;
  height: 80px;
  position: absolute;
  top: 30px;
  left: 16px;
}

.panel-four-item-2:before {
  content: "2";
}

.panel-four-item-2 i {
  border-left: 1px dotted #fff;
  width: 1px;
  height: 120px;
  position: absolute;
  top: 30px;
  left: 16px;
}

.panel-four-item-3:before {
  content: "3";
}

.panel-four.panel-four-m-reverse .panel-four-item {
  padding-left: 0;
}

.panel-four.panel-four-m-reverse .panel-four-item:before {
  display: none;
}

.panel-four-3 {
  background: #0f0f0f;
  border: 2px solid #fff;
  border-radius: 40px;
  width: 1200px;
  margin: 125px auto 0;
  padding: 80px 160px;
}

.panel-four-3-title {
  color: #fff;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: normal;
}

.panel-four-3-sub {
  color: var(--Function-text-tertiary, #9aadce);
  margin-top: 48px;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.panel-four-3-desc {
  color: #fff;
  margin-top: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.panel-four-3 .theme-con {
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  display: flex;
}

.panel-four-3 .theme-con .theme-con-title {
  color: #ebe6e6;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.panel-four-3 .theme-con .theme-group {
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.panel-four-3 .theme-con .theme-item {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 4px;
  display: flex;
  position: relative;
}

.panel-four-3 .theme-con .theme-item .icon {
  opacity: 0;
  width: 24px;
  height: 24px;
  transition: opacity .5s;
  position: absolute;
  top: 8px;
  left: 8px;
}

.panel-four-3 .theme-con .theme-item .icon-bg {
  width: 40px;
  height: 40px;
  transition: transform .5s, -webkit-transform .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-48px);
}

.panel-four-3 .theme-con .theme-item.select .icon-bg {
  transform: translateX(0);
}

.panel-four-3 .theme-con .theme-color-con {
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  display: flex;
}

.panel-four-3 .theme-con .theme-color-con .theme-color-title {
  color: #f5f5f5;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.panel-four-3 .theme-con .theme-color-con .theme-color-items {
  align-items: center;
  gap: 4px;
  display: flex;
}

.panel-four-3 .theme-con .theme-color-con .theme-color-items .color-item {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 6px;
  display: flex;
  position: relative;
  border-width: 0 !important;
}

.panel-four-3 .theme-con .theme-color-con .theme-color-items .color-item .color {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.panel-four-3 .theme-con .theme-color-con .theme-color-items .color-item.select {
  border-width: 2px !important;
}

.panel-four-3 .theme-con .group-btns {
  align-items: center;
  gap: 20px;
  margin-top: 32px;
  display: flex;
}

.panel-four-3 .theme-con .group-btns .btn {
  background: var(--GroupFi-100, #3671ee);
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 184px;
  height: 48px;
  padding: 0 12px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.panel-four-3 .theme-con .group-btns .btn:hover {
  background: var(--GroupFi-80, #3671eecc);
}

.panel-four-3 .theme-con .group-btns .line-btn {
  border: 2px solid #fff;
  background: none !important;
}

.panel-four-3 .theme-con .group-btns .line-btn:hover {
  color: #3671ee;
  border-color: #3671ee;
}

.panel-four-3 .theme-con .theme-dev {
  margin-top: 48px;
}

.panel-four-3 .theme-con .theme-dev .theme-dev-title {
  color: var(--Function-text-tertiary, #9aadce);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.panel-four-3 .theme-con .theme-dev .theme-dev-desc {
  color: var(--, #fff);
  margin-top: 6px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.panel-four-3 .theme-con .theme-dev .theme-dev-desc a {
  color: #3671ee;
  cursor: pointer;
  text-decoration: none;
}

.panel-four-2 {
  width: 1200px;
  margin: 100px auto;
}

.panel-four-2 .line {
  margin-right: 100px;
  padding-right: 100px;
  position: relative;
}

.panel-four-2 .line img {
  height: 54px;
}

.panel-four-2 .line:after {
  content: " ";
  background-color: #fff;
  width: 1px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.panel-four-2 .btn-group {
  gap: 100px;
}

.panel-four-2 .btn-group .btn {
  cursor: pointer;
  background: var(--GroupFi-100, #3671ee);
  color: #fff;
  border-radius: 16px;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  width: 246px;
  height: 52px;
  padding: 0 16px 0 12px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
}

.panel-four-2 .btn-group .btn:hover {
  background: var(--GroupFi-80, #3671eecc);
}

.panel-four-2 .btn-group .btn div {
  text-align: center;
  flex: 1;
}

.panel-five {
  color: var(--Color-Background-White, #fff);
  text-align: center;
  margin-top: 80px;
  font-style: normal;
}

.panel-five .panel-five-con {
  gap: 130px;
}

.panel-five .panel-five-item {
  text-align: left;
  width: 50%;
}

.panel-five-title {
  letter-spacing: -.88px;
  text-align: left;
  font-size: 44px;
  font-weight: 700;
  line-height: 56px;
}

.panel-five-sub {
  letter-spacing: -.56px;
  color: var(--Function-text-tertiary, #9aadce);
  margin-top: 80px;
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.panel-five-img-con {
  flex-wrap: wrap;
  align-items: stretch;
  gap: 28px 20px;
}

.panel-five-img-con img {
  width: 165px;
  max-height: 48px;
}

.panel-five-img-con .img-con-icon img {
  box-sizing: border-box;
  max-height: unset;
  width: 165px;
  height: auto;
  padding-left: 13px;
}

.panel-six {
  background: #041332;
  margin-top: 80px;
  margin-bottom: 80px;
}

.panel-six-content {
  width: 1200px;
  margin: 0 auto;
  padding: 80px;
}

.panel-six-content-title {
  color: var(--Color-Background-White, #fff);
  letter-spacing: -.88px;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.panel-six-content a {
  text-decoration: none;
}

.panel-six-content a:hover {
  opacity: .75;
}

.panel-six-content-btn {
  background: var(--GroupFi-100, #3671ee);
  color: #fff;
  border-radius: 26px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 200px;
  height: 52px;
  padding: 0 16px;
  display: flex;
}

@keyframes rotate-ani-1 {
  0% {
    rotate: none;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes rotate-ani-2 {
  0% {
    rotate: none;
  }

  100% {
    rotate: -360deg;
  }
}

@keyframes blur-image {
  0% {
    filter: blur();
  }

  50% {
    filter: blur(8px);
  }

  100% {
    filter: blur();
  }
}

.fadeIn {
  opacity: 0;
  animation: 0s .8s .8 forwards fadeIn;
}

.fadeIn-1600 {
  animation-delay: 1.6s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.border {
  width: 100%;
  height: 1px;
  margin: 132px 0 100px;
  position: relative;
}

.border:after {
  content: " ";
  background: #ffffff80;
  width: 100vw;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.footer {
  justify-content: space-between;
  align-items: start;
  width: 1200px;
  margin: auto;
  display: flex;
}

.footer .footer-1, .footer .footer-3 {
  flex: 1;
}

.footer .footer-1 a {
  text-decoration: none;
  display: block;
}

.footer .footer-1 .title {
  color: #fff;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.footer .footer-1 .label {
  color: #999;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.footer .footer-1 .label:hover {
  color: #3671ee;
}

.footer .footer-1 img {
  width: 116px;
  height: 32px;
}

.footer .footer-1 .right {
  text-align: left;
  cursor: pointer;
  display: inline-block;
}

.footer .footer-3 .title {
  color: #fff;
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.footer .footer-3 a {
  margin-right: 16px;
  text-decoration: none;
}

.footer .footer-3 a svg {
  width: 24px;
  height: 24px;
}

.footer .footer-3 a:hover svg path {
  fill: #3671ee;
}

.ani-token1-0 {
  bottom: 52px;
  right: 216px;
  rotate: none;
  scale: 1;
}

.ani-token1-1 {
  bottom: 40px;
  right: 69px;
  rotate: -34.45deg;
  scale: 1.5;
}

.left_img_token1 {
  width: 65px;
  height: 49px;
  position: absolute;
  bottom: 52px;
  right: 216px;
  rotate: none;
  scale: 1;
}

.ani-token2-0 {
  bottom: 104px;
  right: 159px;
  rotate: none;
  scale: 1;
}

.ani-token2-1 {
  bottom: 100px;
  right: 170px;
  rotate: 60deg;
  scale: 1.5;
}

.left_img_token2 {
  width: 89px;
  height: 94.5px;
  position: absolute;
  bottom: 104px;
  right: 159px;
  rotate: none;
  scale: 1;
}

.ani-token3-0 {
  bottom: 66px;
  right: 90px;
  rotate: none;
  scale: 1;
}

.ani-token3-1 {
  bottom: 11.26px;
  right: 172.5px;
  rotate: -40.48deg;
  scale: 1;
}

.left_img_token3 {
  width: 74px;
  height: 76px;
  position: absolute;
  bottom: 66px;
  right: 90px;
  rotate: none;
  scale: 1;
}

.ani-token4-0 {
  top: 13px;
  right: 50px;
  rotate: none;
  scale: 1;
}

.ani-token4-1 {
  top: 40px;
  right: 70px;
  rotate: -150deg;
  scale: 1;
}

.left_img_token4 {
  width: 85px;
  height: 76.5px;
  position: absolute;
  top: 13px;
  right: 50px;
  rotate: none;
  scale: 1;
}

.ani-tokens .left_img_token1 {
  animation: .8s forwards token1-0;
  bottom: 52px;
  right: 216px;
  rotate: none;
  scale: 1;
}

.ani-tokens .left_img_token2 {
  animation: .8s forwards token2-0;
  bottom: 104px;
  right: 159px;
  rotate: none;
  scale: 1;
}

.ani-tokens .left_img_token3 {
  animation: .8s forwards token3-0;
  bottom: 66px;
  right: 90px;
  rotate: none;
  scale: 1;
}

.ani-tokens .left_img_token4 {
  animation: .8s forwards token4-0;
  top: 13px;
  right: 50px;
  rotate: none;
  scale: 1;
}

.ani-tokens-leave .left_img_token1 {
  animation: .8s forwards token1-1;
  bottom: 40px;
  right: 69px;
  rotate: -34.45deg;
  scale: 1.5;
}

.ani-tokens-leave .left_img_token2 {
  animation: .8s forwards token2-1;
  bottom: 100px;
  right: 170px;
  rotate: 60deg;
  scale: 1.5;
}

.ani-tokens-leave .left_img_token3 {
  animation: .8s forwards token3-1;
  bottom: 11.26px;
  right: 172.5px;
  rotate: -40.48deg;
  scale: 1;
}

.ani-tokens-leave .left_img_token4 {
  animation: .8s forwards token4-1;
  top: 40px;
  right: 70px;
  rotate: -150deg;
  scale: 1;
}

@keyframes token1-0 {
  to {
    bottom: 40px;
    right: 69px;
    rotate: -34.45deg;
    scale: 1.5;
  }
}

@keyframes token1-1 {
  to {
    bottom: 52px;
    right: 216px;
    rotate: none;
    scale: 1;
  }
}

@keyframes token2-0 {
  to {
    bottom: 100px;
    right: 170px;
    rotate: 60deg;
    scale: 1.5;
  }
}

@keyframes token2-1 {
  to {
    bottom: 104px;
    right: 159px;
    rotate: none;
    scale: 1;
  }
}

@keyframes token3-0 {
  to {
    bottom: 11.26px;
    right: 172.5px;
    rotate: -40.48deg;
    scale: 1;
  }
}

@keyframes token3-1 {
  to {
    bottom: 66px;
    right: 90px;
    rotate: none;
    scale: 1;
  }
}

@keyframes token4-0 {
  to {
    top: 40px;
    right: 70px;
    rotate: -150deg;
    scale: 1;
  }
}

@keyframes token4-1 {
  to {
    top: 13px;
    right: 50px;
    rotate: none;
    scale: 1;
  }
}

.ani-nft1-0 {
  bottom: 110.2px;
  left: 165.9px;
  rotate: none;
  scale: 1;
}

.ani-nft1-1 {
  bottom: 110px;
  left: 146.7px;
  rotate: none;
  scale: 1.1;
}

.right_img_nft1 {
  width: 89px;
  height: 99px;
  position: absolute;
  bottom: 110.2px;
  left: 165.9px;
  rotate: none;
  scale: 1;
}

.ani-nft2-0 {
  bottom: 118.7px;
  left: 77.7px;
  rotate: none;
  scale: 1;
}

.ani-nft2-1 {
  bottom: 70px;
  left: 30px;
  rotate: none;
  scale: .564;
}

.right_img_nft2 {
  width: 120.8px;
  height: 133.8px;
  position: absolute;
  bottom: 118.7px;
  left: 77.7px;
  rotate: none;
  scale: 1;
}

.ani-nft3-0 {
  bottom: 55px;
  left: 5px;
  rotate: none;
  scale: 1;
}

.ani-nft3-1 {
  scale: .58;
  rotate: 0;
  bottom: 33.8px;
  left: 111.5px;
}

.right_img_nft3 {
  width: 93.5px;
  height: 87.7px;
  position: absolute;
  bottom: 55px;
  left: 5px;
  rotate: none;
  scale: 1;
}

.ani-nft4-0 {
  bottom: 27px;
  left: 110px;
  rotate: none;
  scale: 1;
}

.ani-nft4-1 {
  bottom: 48px;
  left: 26px;
  rotate: none;
  scale: 1.57;
}

.right_img_nft4 {
  width: 53px;
  height: 50px;
  position: absolute;
  bottom: 27px;
  left: 110px;
  rotate: none;
  scale: 1;
}

.ani-nft5-0 {
  bottom: 57.5px;
  left: 230px;
  rotate: none;
  scale: 1;
}

.ani-nft5-1 {
  bottom: 57.5px;
  left: 220px;
  rotate: none;
  scale: 1.18;
}

.right_img_nft5 {
  width: 73px;
  height: 68px;
  position: absolute;
  bottom: 57.5px;
  left: 230px;
  rotate: none;
  scale: 1;
}

.ani-nfts .right_img_nft1 {
  animation: .8s forwards nft1-0;
  bottom: 110.2px;
  left: 165.9px;
  rotate: none;
  scale: 1;
}

.ani-nfts .right_img_nft2 {
  animation: .8s forwards nft2-0;
  bottom: 118.7px;
  left: 77.7px;
  rotate: none;
  scale: 1;
}

.ani-nfts .right_img_nft3 {
  animation: .8s forwards nft3-0;
  bottom: 55px;
  left: 5px;
  rotate: none;
  scale: 1;
}

.ani-nfts .right_img_nft4 {
  animation: .8s forwards nft4-0;
  bottom: 27px;
  left: 110px;
  rotate: none;
  scale: 1;
}

.ani-nfts .right_img_nft5 {
  animation: .8s forwards nft5-0;
  bottom: 57.5px;
  left: 230px;
  rotate: none;
  scale: 1;
}

.ani-nfts-leave .right_img_nft1 {
  animation: .8s forwards nft1-1;
  bottom: 110px;
  left: 146.7px;
  rotate: none;
  scale: 1.1;
}

.ani-nfts-leave .right_img_nft2 {
  animation: .8s forwards nft2-1;
  bottom: 70px;
  left: 30px;
  rotate: none;
  scale: .564;
}

.ani-nfts-leave .right_img_nft3 {
  scale: .58;
  rotate: 0;
  animation: .8s forwards nft3-1;
  bottom: 33.8px;
  left: 111.5px;
}

.ani-nfts-leave .right_img_nft4 {
  animation: .8s forwards nft4-1;
  bottom: 48px;
  left: 26px;
  rotate: none;
  scale: 1.57;
}

.ani-nfts-leave .right_img_nft5 {
  animation: .8s forwards nft5-1;
  bottom: 57.5px;
  left: 220px;
  rotate: none;
  scale: 1.18;
}

@keyframes nft1-0 {
  to {
    bottom: 110px;
    left: 146.7px;
    rotate: none;
    scale: 1.1;
  }
}

@keyframes nft1-1 {
  to {
    bottom: 110.2px;
    left: 165.9px;
    rotate: none;
    scale: 1;
  }
}

@keyframes nft2-0 {
  to {
    bottom: 70px;
    left: 30px;
    rotate: none;
    scale: .564;
  }
}

@keyframes nft2-1 {
  to {
    bottom: 118.7px;
    left: 77.7px;
    rotate: none;
    scale: 1;
  }
}

@keyframes nft3-0 {
  to {
    scale: .58;
    rotate: 0;
    bottom: 33.8px;
    left: 111.5px;
  }
}

@keyframes nft3-1 {
  to {
    bottom: 55px;
    left: 5px;
    rotate: none;
    scale: 1;
  }
}

@keyframes nft4-0 {
  to {
    bottom: 48px;
    left: 26px;
    rotate: none;
    scale: 1.57;
  }
}

@keyframes nft4-1 {
  to {
    bottom: 27px;
    left: 110px;
    rotate: none;
    scale: 1;
  }
}

@keyframes nft5-0 {
  to {
    bottom: 57.5px;
    left: 220px;
    rotate: none;
    scale: 1.18;
  }
}

@keyframes nft5-1 {
  to {
    bottom: 57.5px;
    left: 230px;
    rotate: none;
    scale: 1;
  }
}

.hidden {
  display: none !important;
}

.mask-con {
  z-index: 1000;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 64px 0 0;
}

.mask-panel {
  background-color: #000;
  width: 100vw;
  height: 100%;
}

.mask-panel .item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  display: flex;
}

.mask-panel .item a {
  color: var(--Color-t1, #fafafa);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.connect-con {
  z-index: 1000;
  background-color: #000000bf;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.connect-con .connect-pannel {
  border: 2px solid var(--White-100, #fff);
  background: var(--Black, #000);
  border-radius: 16px;
  width: 360px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.connect-con .connect-pannel .connect-header {
  color: #fff;
  border-bottom: 1.5px solid var(--White-100, #fff);
  height: 60px;
  padding: 0 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.connect-con .connect-pannel .connect-header svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.connect-con .connect-pannel .connect-header .connect-header-title {
  margin-left: 12px;
}

.connect-con .connect-pannel .connect-header .connect-header-close svg {
  width: 12px;
  height: 12px;
}

.connect-con .connect-pannel .connect-item-con {
  padding: 16px;
}

.connect-con .connect-pannel .connect-item {
  cursor: pointer;
  text-align: left;
  background: #22252d;
  border: 2px solid #fff;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 16px;
  padding: 14px;
}

.connect-con .connect-pannel .connect-item:hover {
  border-color: #3671ee;
}

.connect-con .connect-pannel .connect-item.disabled {
  cursor: not-allowed;
  opacity: .3;
  border-color: #fff;
}

.connect-con .connect-pannel .connect-item img {
  width: 44px;
  height: 44px;
  margin-right: 14px;
}

.lg-screen .lg-hide, .small-screen .sm-hide {
  display: none !important;
}

.small-screen .content {
  max-width: 100vw;
  padding-top: 20px;
}

.small-screen .m-header {
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 16px;
  display: flex;
}

.small-screen .m-header .m-header-left {
  align-items: center;
  display: flex;
}

.small-screen .m-header .m-header-left svg {
  width: 24px;
  height: 24px;
}

.small-screen .m-header .m-header-left svg:active {
  opacity: .75;
}

.small-screen .m-header .m-header-left .m-logo {
  width: 116px;
  height: 32px;
  margin-left: 16px;
}

.small-screen .m-header .m-header-left .m-logo img {
  width: 100%;
  height: 100%;
}

.small-screen .m-header .m-header-right {
  align-items: center;
  display: flex;
}

.small-screen .m-header .m-header-right .twitler {
  margin-left: 16px;
}

.small-screen .panel-one {
  height: unset;
  width: 100%;
}

.small-screen .panel-one .title {
  width: 100%;
  margin-bottom: 0;
}

.small-screen .panel-one .sub_title {
  margin-top: 24px;
  margin-bottom: 32px;
  font-size: 15px;
}

.small-screen .panel-one .ani-con {
  height: 50vw;
  position: relative;
}

.small-screen .panel-one .left-img-con {
  left: -45vw;
  scale: .6;
}

.small-screen .panel-one .right-img-con {
  right: -30vw;
  scale: .6;
}

.small-screen .panel-one .left-img, .small-screen .panel-one .right-img {
  width: 100%;
  height: 100%;
}

.small-screen .panel-one-new {
  flex-direction: column-reverse;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 0 !important;
}

.small-screen .panel-one-new .left, .small-screen .panel-one-new .right {
  text-align: center;
}

.small-screen .panel-one-new .left img, .small-screen .panel-one-new .right img {
  width: 80%;
  height: auto;
}

.small-screen .panel-one-new .left img {
  margin-bottom: 10px;
}

.small-screen .panel-one-new .left .label {
  font-size: 24px;
  line-height: 36px;
}

.small-screen .panel-one-new .left .group-btns {
  justify-content: space-between;
  gap: 0;
  margin-top: 32px !important;
}

.small-screen .panel-one-new .left .group-btns .btn {
  width: 50vw;
  scale: .8;
}

.small-screen .panel-one-new .left .guide-step-1 {
  position: inherit;
  min-width: unset;
  width: calc(100vw - 40px);
  margin: 20px 20px 0;
}

.small-screen .panel-one-new .left .guide-step-1 .guide-step-label {
  text-align: left;
}

.small-screen .panel-four-2 {
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 24px;
}

.small-screen .panel-four-2 .line {
  text-align: center;
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}

.small-screen .panel-four-2 .line:after {
  display: none;
}

.small-screen .panel-four-2 .line img {
  width: 70%;
  height: auto;
}

.small-screen .panel-four-2 .btn-group {
  flex-direction: column;
  justify-content: center;
  gap: 0;
}

.small-screen .panel-four-2 .btn-group .btn {
  margin-top: 16px;
}

.small-screen .panel-four-3 {
  box-sizing: border-box;
  background: none;
  border: none;
  padding: 16px;
  width: 100% !important;
}

.small-screen .panel-one-new, .small-screen .panel-four-2, .small-screen .panel-two, .small-screen .panel-three, .small-screen .panel-four, .small-screen .panel-five, .small-screen .panel-six {
  box-sizing: border-box;
  padding: 16px;
  width: 100% !important;
}

.small-screen .panel-two .pannel-two-item-title {
  font-size: 32px !important;
}

.small-screen .panel-two .pannel-two-item-desc {
  font-size: 12px !important;
}

.small-screen .panel-two .pannel-two-item-desc-1 {
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
}

.small-screen .panel-three {
  flex-flow: column wrap;
}

.small-screen .panel-three-img {
  width: 100% !important;
  height: auto !important;
}

.small-screen .panel-three-right {
  padding-left: 16px;
  width: 100% !important;
}

.small-screen .panel-three-sub-title {
  margin-top: 24px;
}

.small-screen .panel-three-title {
  margin: 16px 0;
  font-size: 24px;
  line-height: 40px;
}

.small-screen .panel-three-desc-1 {
  margin-bottom: 16px;
  font-size: 20px;
}

.small-screen .panel-three-desc-2 {
  margin-bottom: 10px;
  font-size: 14px;
}

.small-screen .panel-four {
  flex-flow: column wrap;
  margin-top: 16px;
}

.small-screen .panel-four.panel-four-m-reverse {
  flex-direction: column-reverse;
}

.small-screen .panel-four-left {
  width: 100% !important;
}

.small-screen .panel-four-img {
  width: 100% !important;
  height: auto !important;
}

.small-screen .panel-four-title {
  margin: 16px 0;
  font-size: 24px;
  line-height: 40px;
}

.small-screen .panel-four-item {
  margin-bottom: 16px;
  padding-left: 40px;
}

.small-screen .panel-four-item:before {
  width: 24px;
  height: 24px;
  font-size: 12px;
  top: 6px;
}

.small-screen .panel-four-item i {
  left: 12px;
}

.small-screen .panel-four-item-title {
  font-size: 20px;
  line-height: 24px;
}

.small-screen .panel-four-item-desc {
  font-size: 16px;
  line-height: 20px;
}

.small-screen .panel-four-item-2 i {
  height: 90px;
}

.small-screen .panel-five {
  margin-top: 16px;
}

.small-screen .panel-five .panel-five-con {
  gap: 16px;
}

.small-screen .panel-five-img-con {
  flex-wrap: wrap;
}

.small-screen .panel-five-title {
  font-size: 24px;
}

.small-screen .panel-five-sub {
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 20px;
}

.small-screen .panel-six {
  margin-top: 32px;
  margin-bottom: 12px;
}

.small-screen .panel-six-content {
  box-sizing: border-box;
  flex-direction: column;
  align-items: start;
  padding: 0;
  width: 100% !important;
}

.small-screen .panel-six-content-title {
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 32px;
}

.small-screen .panel-six-content-btn {
  height: 44px;
}

.small-screen .border {
  margin: 24px 0;
}

.small-screen .footer {
  box-sizing: border-box;
  flex-direction: column;
  width: 100vw;
  padding: 40px;
}

.small-screen .footer .footer-1, .small-screen .footer .footer-2, .small-screen .footer .footer-3 {
  margin-bottom: 24px;
}

::-webkit-scrollbar {
  background-color: #000;
  width: 6px;
  height: 0;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0000001a;
  border-radius: 4px;
}

#groupfi_btn {
  background-image: var(--chatbox-image-dark) !important;
}

#groupfi_btn:hover {
  background-image: var(--chatbox-image-light-hover) !important;
}

/*# sourceMappingURL=groupFi.74d19b1f.css.map */
