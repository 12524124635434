@font-face {
    // font-family: 'Digital Numbers';
    // src: url('../../common/font/Digital-Numbers.ttf');
    font-family: 'HarmonyOS_Sans_Medium';
    src: url('./HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf');
}
@font-face {
    // font-family: 'Digital Numbers';
    // src: url('../../common/font/Digital-Numbers.ttf');
    font-family: 'HarmonyOS_Sans_Regular';
    src: url('./HarmonyOS_Sans/HarmonyOS_Sans_Regular.ttf');
}
* {
    box-sizing: border-box;
    font-family: 'HarmonyOS_Sans_Medium';
}
html {
    font-weight: 400;
    font-style: normal;
}
body {
    margin: 0;
    background: #000000;
    color: #fff;
    // max-width: 1200px;
    margin: 0 auto;
}
/* 设置滚动条的样式 */
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}
// img {
//     display: block;
// }
.content {
    padding-top: 36px;
    max-width: 1200px;
    margin: 0 auto;
}
.content-retract {
    padding: 0 32px;
}
.h50 {
    height: 50px;
}
.h80 {
    height: 80px;
}
.mb64 {
    margin-bottom: 64px;
}
.common_btns {
    margin: auto;
    width: 240px;
    height: 48px;
    line-height: 48px;
    padding: 0 16px 0 16px;
    border-radius: 26px;
    background: #3671ee;
    text-align: center;
    color: #fff;
    cursor: pointer;
    &:hover {
        opacity: 0.4;
    }
    a {
        text-decoration: none;
        color: #fff;
    }
}
.header {
    // position: fixed;
    z-index: 10;
    width: 100%;
    // max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 36px;
    border-bottom: 0.5px solid #808080;
    .links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            // flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .medium,
        .github,
        .twitler {
            margin-right: 32px;
            // padding-left: 40px;
            line-height: 26px;
            background-repeat: no-repeat;
            background-position: left center;
            font-size: 15px;
            font-weight: 700;
            text-decoration: none;
            color: #ffffff;
            transition: background-color 0.6s ease, color 0.4s ease;
        }
        .medium {
            padding: 4px 12px;
            border-radius: 12px;
            cursor: pointer;
            &:hover {
                color: #3671ee;
                // background: #fff;
            }
        }
        .chatbox {
            cursor: pointer;
            position: relative;
            svg {
                transform: rotate(0deg);
                transition: background-color 0.6s ease, color 0.4s ease transform 1s ease-in-out;
                margin-left: 8px;
            }
            &:hover {
                // svg {
                //     transform: rotate(-180deg);
                //     color: #282828;
                // }
            }
            .chatbox-con {
                display: none;
            }
            &:hover {
                .chatbox-con {
                    position: absolute;
                    top: 20px;
                    width: 100%;
                    left: 0;
                    border-top: 4px solid transparent;
                    display: block;
                    z-index: 1;
                    padding-top: 20px;
                    .chatbox-panel {
                        // border: 2px solid rgba(0, 0, 0, 0.3);
                        border-radius: 8px;
                        background: #fff;
                        overflow: hidden;
                        padding: 8px;
                        .chatbox-item {
                            cursor: pointer;
                            width: 100%;
                            overflow: hidden;
                            display: flex;
                            font-size: 15px;
                            align-items: center;
                            padding: 4px 4px;
                            border-radius: 4px;
                            // border-bottom: 2px solid rgba(0, 0, 0, 0.3);
                            &:last-of-type {
                                border-bottom: 0;
                            }
                            a {
                                // padding: 4px 12px;
                                padding-left: 12px;
                                color: #333;
                                display: block;
                                text-decoration: none;
                            }
                            &:hover {
                                // background: #3671ee;
                                background-color: #eeeeee;
                                a {
                                    // color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        .twitler {
            width: 24px;
            height: 24px;
            // margin-left: 200px;
            &:hover {
                color: #3671ee;
            }
        }
        .github {
            // padding-left: 35px;
            margin-right: 28px;
            background-size: 19px;
            // background-image: url(../image/github-1@2x.png);
            width: 24px;
            height: 24px;
            &:hover {
                color: #3671ee;
            }
        }
        .right {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            .icon-label {
                transition: background-color 0.6s ease, color 0.4s ease;
                margin-left: 8px;
            }
            &:hover {
                .icon-label {
                    color: #3671ee;
                }
            }
        }
    }
}
.top-connect {
    // position: absolute;
    // top: 30px;
    // right: 24px;
    display: inline-block;
    .hidden {
        display: none !important;
    }
    .connect-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background: #3671ee;
        min-width: 158px;
        padding: 0 12px;
        height: 38px;
        cursor: pointer;
        .btn-label {
            // margin-left: 6px;
            color: #fff;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
        transition: opacity 0.5s ease;
        svg {
            height: 20px;
            margin-right: 6px;
            // transition: transform 0.5s ease;
        }
        &:hover {
            opacity: 0.8;
            svg {
                // transform: scale(1.1);
            }
        }
    }
    .has-connect-btn {
        display: inline-flex;
        min-width: 158px;
        padding: 0 12px;
        height: 38px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid #fff;
        position: relative;
        cursor: pointer;
        .logout-con {
            display: none;
        }
        &:hover {
            .logout-con {
                position: absolute;
                top: 38px;
                left: 0;
                border-top: 4px solid transparent;
                display: block;
                .logout {
                    display: flex;
                    cursor: pointer;
                    height: 38px;
                    width: 158px;
                    padding: 0px 16px;
                    align-items: center;
                    align-self: stretch;
                    color: #fff;
                    font-family: Arial;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    background: #202124;
                    border-radius: 12px;
                    height: 48px;
                    text-align: right;
                    border: 1px solid #404447;
                }
            }
            svg {
                transform: scaleY(-1);
            }
        }
        .address {
            color: #fff;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
        }
        svg {
            width: 12px;
            height: 12px;
        }
    }
}
.panel {
    &-one-new {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            position: relative;
            img {
                width: 585px;
                height: 74px;
                margin-bottom: 52px;
            }
            .label {
                color: #fff;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                font-family: HarmonyOS_Sans_Regular;
                a {
                    color: var(--GroupFi-100, #3671ee);
                    text-decoration: none;
                }
            }
            .group-btns {
                display: flex;
                align-items: center;
                gap: 28px;
                margin-top: 56px;
                .btn {
                    display: flex;
                    min-width: 184px;
                    height: 48px;
                    padding: 0px 12px;
                    font-size: 18px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 16px;
                    background: var(--GroupFi-100, #3671ee);
                    cursor: pointer;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        background: var(--GroupFi-80, #3671eecc);
                    }
                }
                .line-btn {
                    background: transparent !important;
                    border: 2px solid #fff;
                    &:hover {
                        border-color: #3671ee;
                        color: #3671ee;
                    }
                }
            }
            .guide-step-1 {
                display: flex;
                align-items: center;
                padding: 12px 16px;
                border-radius: 12px;
                border: 1.5px solid #fff;
                background: var(--, #000);
                min-width: 393px;
                margin-top: 24px;
                position: absolute;
                bottom: -94px;
                @keyframes guide-point-ani {
                    0% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.1);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
                .guide-point {
                    width: 23px;
                    height: auto;
                    margin-bottom: 0;
                    margin-right: 16px;
                    animation: guide-point-ani 1s linear infinite;
                }
                .guide-step-label {
                    color: #fff;
                    flex: 1;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    span {
                        color: var(--GroupFi-100, #3671ee);
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                    margin-right: 12px;
                }
                .guide-close {
                    margin-bottom: 24px;
                    cursor: pointer;
                }
            }
        }
        .right {
            img {
                width: 570px;
                height: 556px;
            }
        }
    }
    &-one {
        height: 300px;
        position: relative;
        text-align: center;
        overflow: hidden;

        .title {
            // max-width: 80%;
            max-width: 650px;
        }
        // .animation {
        // }

        .hover {
            color: #3671ee;
            opacity: 0.3;
            cursor: pointer;
        }

        .left_img {
            width: 100%;
        }
        .left-img-con {
            width: 416px;
            position: absolute;
            left: -100px;
            top: -10px;
            animation-delay: 800ms;
            animation-duration: 800ms;
            z-index: -1;
        }
        .right_img {
            width: 100%;
        }
        .right-img-con {
            width: 380px;
            position: absolute;
            right: -70px;
            top: -15px;
            animation-delay: 1600ms;
            animation-duration: 800ms;
            z-index: -1;
        }

        .sub_title {
            font-size: 23px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }
    }
    &-two {
        padding-top: 64px;
        padding-bottom: 104px;
        width: 1200px;
        margin: 0 auto;
        color: var(--Color-Background-White, #fff);
        text-align: center;
        // font-family: 'HarmonyOS Sans SC';
        font-style: normal;
        .pannel-two-item {
            width: 373px;
            .pannel-two-item-title {
                font-size: 48px;
                font-weight: 700;
                line-height: 56px;
                margin-bottom: 10px;
            }
            .panel-two-item-desc {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
            }
        }
        .pannel-two-item-desc-1 {
            font-size: 10px;
            line-height: 28px;
            color: var(--Function-text-tertiary, #9aadce);
            text-align: right;
            width: 1026px;
            margin: 0 auto;
            margin-top: 20px;
        }
    }
    &-three {
        width: 1200px;
        margin: 0 auto;
        &-img {
            width: 513.7px;
            height: 540px;
        }
        .panel-three-right {
            width: 620px;
            color: var(--Color-Background-White, #fff);
            // font-family: 'HarmonyOS Sans SC';
            font-style: normal;
        }
        &-sub-title {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            // margin-top: 40px;
        }
        &-title {
            margin: 32px 0;
            // font-family: 'HarmonyOS Sans SC';
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 56px; /* 127.273% */
            letter-spacing: -0.88px;
        }
        &-desc-1 {
            font-size: 28px;
            font-weight: 500;
            line-height: 32px; /* 114.286% */
            letter-spacing: -0.56px;
            color: var(--Function-text-tertiary, #9aadce);
            margin-bottom: 32px;
        }
        &-desc-2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 16px;
            position: relative;
            padding-left: 30px;
            &::before {
                content: ' ';
                left: 0;
                top: 2px;
                width: 20px;
                height: 20px;
                position: absolute;
                background-image: url(../image/pannel-three-icon.png);
                background-size: 100% 100%;
            }
            i {
                color: var(--Function-text-tertiary, #9aadce);
            }
        }
        &-desc-3 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--Function-text-tertiary, #9aadce);
        }
    }
    &-four {
        width: 1200px;
        margin: 0 auto;
        margin-top: 125px;
        // &.panel-four-m-reverse {
        //     margin-top: 125px;
        // }
        &-img {
            width: 540px;
            height: 546px;
        }
        &-left {
            color: var(--Color-Background-White, #fff);
            // font-family: 'HarmonyOS Sans SC';
            font-style: normal;
            width: 620px;
        }
        &-title {
            font-size: 44px;
            font-weight: 700;
            line-height: 56px; /* 127.273% */
            letter-spacing: -0.88px;
            margin-top: 40px;
            margin-bottom: 32px;
        }
        &-item {
            margin-bottom: 40px;
            position: relative;
            padding-left: 72px;
            &-title {
                font-size: 28px;
                font-weight: 500;
                line-height: 32px; /* 114.286% */
                letter-spacing: -0.56px;
                color: var(--Function-text-tertiary, #9aadce);
            }
            &-desc {
                font-size: 20px;
                font-weight: 500;
                line-height: 28px;
                margin-top: 4px;
            }
            &::before {
                content: '1';
                background: #fff;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                border-radius: 50%;
                display: flex;
                width: 32px;
                height: 32px;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
            }
            &-1 {
                &::before {
                    content: '1';
                }
                i {
                    height: 80px;
                    width: 1px;
                    border-left: 1px dotted #fff;
                    position: absolute;
                    left: 16px;
                    top: 30px;
                }
            }
            &-2 {
                &::before {
                    content: '2';
                }
                i {
                    height: 120px;
                    width: 1px;
                    border-left: 1px dotted #fff;
                    position: absolute;
                    left: 16px;
                    top: 30px;
                }
            }
            &-3 {
                &::before {
                    content: '3';
                }
            }
        }
        &.panel-four-m-reverse {
            .panel-four-item {
                padding-left: 0;
                &::before {
                    display: none;
                }
            }
        }
    }
    &-four-3 {
        width: 1200px;
        margin: 0 auto;
        margin-top: 125px;
        border-radius: 40px;
        border: 2px solid #fff;
        background: #0f0f0f;
        padding: 80px 160px;
        &-title {
            color: #fff;
            text-align: center;
            font-size: 44px;
            font-weight: 700;
            line-height: normal;
        }
        &-sub {
            color: var(--Function-text-tertiary, #9aadce);
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 48px;
        }
        &-desc {
            color: #fff;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            margin-top: 8px;
            line-height: normal;
        }
        .theme-con {
            margin-top: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .theme-con-title {
                color: #ebe6e6;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .theme-group {
                display: flex;
                align-items: center;
                margin-top: 16px;
            }
            .theme-item {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 4px;
                position: relative;
                cursor: pointer;
                .icon {
                    width: 24px;
                    height: 24px;
                    transition: opacity 0.5s ease;
                    position: absolute;
                    left: 8px;
                    top: 8px;
                    opacity: 0;
                }
                .icon-bg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 40px;
                    height: 40px;
                    transition: transform 0.5s ease;
                    transform: translateX(-48px);
                }
                &.select {
                    .icon-bg {
                        transform: translateX(0px);
                    }
                }
            }
            .theme-color-con {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-top: 28px;
                .theme-color-title {
                    color: #f5f5f5;
                    text-align: center;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 20px;
                }
                .theme-color-items {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    .color-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        cursor: pointer;
                        position: relative;
                        padding: 6px;
                        box-sizing: border-box;
                        border-radius: 50%;
                        .color {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                        border-width: 0 !important;
                        &.select {
                            border-width: 2px !important;
                        }
                    }
                }
            }
            .group-btns {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 32px;
                .btn {
                    display: flex;
                    min-width: 184px;
                    height: 48px;
                    padding: 0px 12px;
                    font-size: 18px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 16px;
                    background: var(--GroupFi-100, #3671ee);
                    cursor: pointer;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        background: var(--GroupFi-80, #3671eecc);
                    }
                }
                .line-btn {
                    background: transparent !important;
                    border: 2px solid #fff;
                    &:hover {
                        border-color: #3671ee;
                        color: #3671ee;
                    }
                }
            }
            .theme-dev {
                margin-top: 48px;
                .theme-dev-title {
                    color: var(--Function-text-tertiary, #9aadce);
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .theme-dev-desc {
                    color: var(--, #fff);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 6px;
                    a {
                        color: #3671ee;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &-four-2 {
        margin: 100px auto 100px auto;
        width: 1200px;
        .line {
            margin-right: 100px;
            padding-right: 100px;
            position: relative;
            img {
                height: 54px;
            }
            &::after {
                position: absolute;
                content: ' ';
                right: 0;
                width: 1px;
                top: 50%;
                transform: translateY(-50%);
                height: 40px;
                background-color: #fff;
            }
        }
        .btn-group {
            gap: 100px;
            .btn {
                cursor: pointer;
                display: flex;
                width: 246px;
                height: 52px;
                padding: 0px 16px 0px 12px;
                justify-content: flex-end;
                align-items: center;
                gap: 4px;
                flex-shrink: 0;
                border-radius: 16px;
                background: var(--GroupFi-100, #3671ee);
                color: #fff;
                font-size: 18px;
                text-decoration: none;
                &:hover {
                    background: var(--GroupFi-80, #3671eecc);
                }
                div {
                    flex: 1;
                    text-align: center;
                }
            }
        }
    }
    &-five {
        color: var(--Color-Background-White, #fff);
        text-align: center;
        // font-family: 'HarmonyOS Sans SC';
        font-style: normal;
        margin-top: 80px;
        .panel-five-con {
            gap: 130px;
        }
        .panel-five-item {
            width: 50%;
            text-align: left;
        }
        &-title {
            font-size: 44px;
            font-weight: 700;
            line-height: 56px; /* 127.273% */
            letter-spacing: -0.88px;
            text-align: left;
        }
        &-sub {
            font-size: 28px;
            font-weight: 500;
            line-height: 32px; /* 114.286% */
            letter-spacing: -0.56px;
            margin-bottom: 32px;
            margin-top: 80px;
            color: var(--Function-text-tertiary, #9aadce);
        }
        &-img-con {
            flex-wrap: wrap;
            gap: 28px 20px;
            align-items: stretch;
            // margin-left: -13px;
            img {
                width: 165px;
                max-height: 48px;
                // margin-bottom: 8px;
            }
            .img-con-icon {
                // margin-left: 12px;
                // margin-right: 12px;
                img {
                    box-sizing: border-box;
                    padding-left: 13px;
                    height: auto;
                    width: 165px;
                    max-height: unset;
                }
            }
        }
    }
    &-six {
        margin-top: 80px;
        background: #041332;
        margin-bottom: 80px;
        &-content {
            width: 1200px;
            margin: 0 auto;
            padding: 80px;
            &-title {
                color: var(--Color-Background-White, #fff);
                // font-family: 'HarmonyOS Sans SC';
                font-size: 44px;
                font-style: normal;
                font-weight: 700;
                line-height: 56px; /* 127.273% */
                letter-spacing: -0.88px;
            }
            a {
                text-decoration: none;
                &:hover {
                    opacity: 0.75;
                }
            }
            &-btn {
                display: flex;
                width: 200px;
                height: 52px;
                padding: 0px 16px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                flex-shrink: 0;
                border-radius: 26px;
                background: var(--GroupFi-100, #3671ee);
                color: #fff;
            }
        }
    }
}
@keyframes rotate-ani-1 {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}
@keyframes rotate-ani-2 {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: -360deg;
    }
}
@keyframes blur-image {
    0% {
        filter: blur(0px);
    }
    50% {
        filter: blur(8px);
    }
    100% {
        filter: blur(0px);
    }
}
.fadeIn {
    animation: fadeIn 0.8;
    animation-fill-mode: forwards;
    animation-delay: 800ms;
    opacity: 0;
}
.fadeIn-1600 {
    animation-delay: 1600ms;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.border {
    width: 100%;
    margin: 132px 0 100px 0;
    position: relative;
    height: 1px;
    &::after {
        content: ' ';
        height: 1px;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        display: block;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
    }
}
.footer {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    .footer-1,
    .footer-3 {
        flex: 1;
    }
    .footer-1 {
        a {
            display: block;
            text-decoration: none;
        }
        .title {
            color: #fff;
            // font-family: 'HarmonyOS Sans SC';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            margin-bottom: 16px;
        }
        .label {
            color: #999;
            // font-family: 'HarmonyOS Sans SC';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            margin-bottom: 16px;
            cursor: pointer;
            &:hover {
                color: rgba(54, 113, 238, 1);
            }
        }
        img {
            width: 116px;
            height: 32px;
        }
        .right {
            text-align: left;
            display: inline-block;
            cursor: pointer;
        }
    }
    .footer-3 {
        .title {
            color: #fff;
            // font-family: 'HarmonyOS Sans SC';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
            margin-bottom: 16px;
        }
        a {
            margin-right: 16px;
            text-decoration: none;
            svg {
                width: 24px;
                height: 24px;
            }
            &:hover {
                svg path {
                    fill: rgba(54, 113, 238, 1);
                }
            }
        }
    }
}

.ani-token1-0 {
    rotate: 0deg;
    scale: 1;
    right: 216px;
    bottom: 52px;
}
.ani-token1-1 {
    scale: 1.5;
    rotate: -34.45deg;
    right: 69px;
    bottom: 40px;
}
.left_img_token1 {
    width: 65px;
    height: 49px;
    position: absolute;
    .ani-token1-0;
}

.ani-token2-0 {
    rotate: 0deg;
    scale: 1;
    right: 159px;
    bottom: 104px;
}
.ani-token2-1 {
    scale: 1.5;
    rotate: 60deg;
    right: 170px;
    bottom: 100px;
}
.left_img_token2 {
    width: 89px;
    height: 94.5px;
    position: absolute;
    .ani-token2-0;
}

.ani-token3-0 {
    rotate: 0deg;
    scale: 1;
    right: 90px;
    bottom: 66px;
}
.ani-token3-1 {
    scale: 1;
    rotate: -40.48deg;
    right: 172.5px;
    bottom: 11.26px;
}
.left_img_token3 {
    width: 74px;
    height: 76px;
    position: absolute;
    .ani-token3-0;
}

.ani-token4-0 {
    rotate: 0deg;
    scale: 1;
    right: 50px;
    top: 13px;
}
.ani-token4-1 {
    scale: 1;
    rotate: -150deg;
    right: 70px;
    top: 40px;
}
.left_img_token4 {
    width: 85px;
    height: 76.5px;
    position: absolute;
    .ani-token4-0;
}

.ani-tokens {
    .left_img_token1 {
        .ani-token1-0;
        animation: token1-0 0.8s forwards;
    }
    .left_img_token2 {
        .ani-token2-0;
        animation: token2-0 0.8s forwards;
    }
    .left_img_token3 {
        .ani-token3-0;
        animation: token3-0 0.8s forwards;
    }
    .left_img_token4 {
        .ani-token4-0;
        animation: token4-0 0.8s forwards;
    }
}
.ani-tokens-leave {
    .left_img_token1 {
        .ani-token1-1;
        animation: token1-1 0.8s forwards;
    }
    .left_img_token2 {
        .ani-token2-1;
        animation: token2-1 0.8s forwards;
    }
    .left_img_token3 {
        .ani-token3-1;
        animation: token3-1 0.8s forwards;
    }
    .left_img_token4 {
        .ani-token4-1;
        animation: token4-1 0.8s forwards;
    }
}
@keyframes token1-0 {
    to {
        .ani-token1-1;
    }
}
@keyframes token1-1 {
    to {
        .ani-token1-0;
    }
}
@keyframes token2-0 {
    to {
        .ani-token2-1;
    }
}
@keyframes token2-1 {
    to {
        .ani-token2-0;
    }
}
@keyframes token3-0 {
    to {
        .ani-token3-1;
    }
}
@keyframes token3-1 {
    to {
        .ani-token3-0;
    }
}
@keyframes token4-0 {
    to {
        .ani-token4-1;
    }
}
@keyframes token4-1 {
    to {
        .ani-token4-0;
    }
}

.ani-nft1-0 {
    rotate: 0deg;
    scale: 1;
    left: 165.9px;
    bottom: 110.2px;
}
.ani-nft1-1 {
    scale: 1.1;
    rotate: 0deg;
    left: 146.7px;
    bottom: 110px;
}
.right_img_nft1 {
    width: 89px;
    height: 99px;
    position: absolute;
    .ani-nft1-0;
}

.ani-nft2-0 {
    rotate: 0deg;
    scale: 1;
    left: 77.7px;
    bottom: 118.7px;
}
.ani-nft2-1 {
    scale: 0.564;
    rotate: 0deg;
    left: 30px;
    bottom: 70px;
}
.right_img_nft2 {
    width: 120.8px;
    height: 133.8px;
    position: absolute;
    .ani-nft2-0;
}

.ani-nft3-0 {
    rotate: 0deg;
    scale: 1;
    left: 5px;
    bottom: 55px;
}
.ani-nft3-1 {
    scale: 0.58;
    rotate: 0;
    left: 111.5px;
    bottom: 33.8px;
}
.right_img_nft3 {
    width: 93.5px;
    height: 87.7px;
    position: absolute;
    .ani-nft3-0;
}

.ani-nft4-0 {
    rotate: 0deg;
    scale: 1;
    left: 110px;
    bottom: 27px;
}
.ani-nft4-1 {
    scale: 1.57;
    rotate: 0deg;
    left: 26px;
    bottom: 48px;
}
.right_img_nft4 {
    width: 53px;
    height: 50px;
    position: absolute;
    .ani-nft4-0;
}

.ani-nft5-0 {
    rotate: 0deg;
    scale: 1;
    left: 230px;
    bottom: 57.5px;
}
.ani-nft5-1 {
    scale: 1.18;
    rotate: 0deg;
    left: 220px;
    bottom: 57.5px;
}
.right_img_nft5 {
    width: 73px;
    height: 68px;
    position: absolute;
    .ani-nft5-0;
}

.ani-nfts {
    .right_img_nft1 {
        .ani-nft1-0;
        animation: nft1-0 0.8s forwards;
    }
    .right_img_nft2 {
        .ani-nft2-0;
        animation: nft2-0 0.8s forwards;
    }
    .right_img_nft3 {
        .ani-nft3-0;
        animation: nft3-0 0.8s forwards;
    }
    .right_img_nft4 {
        .ani-nft4-0;
        animation: nft4-0 0.8s forwards;
    }
    .right_img_nft5 {
        .ani-nft5-0;
        animation: nft5-0 0.8s forwards;
    }
}
.ani-nfts-leave {
    .right_img_nft1 {
        .ani-nft1-1;
        animation: nft1-1 0.8s forwards;
    }
    .right_img_nft2 {
        .ani-nft2-1;
        animation: nft2-1 0.8s forwards;
    }
    .right_img_nft3 {
        .ani-nft3-1;
        animation: nft3-1 0.8s forwards;
    }
    .right_img_nft4 {
        .ani-nft4-1;
        animation: nft4-1 0.8s forwards;
    }
    .right_img_nft5 {
        .ani-nft5-1;
        animation: nft5-1 0.8s forwards;
    }
}
@keyframes nft1-0 {
    to {
        .ani-nft1-1;
    }
}
@keyframes nft1-1 {
    to {
        .ani-nft1-0;
    }
}
@keyframes nft2-0 {
    to {
        .ani-nft2-1;
    }
}
@keyframes nft2-1 {
    to {
        .ani-nft2-0;
    }
}
@keyframes nft3-0 {
    to {
        .ani-nft3-1;
    }
}
@keyframes nft3-1 {
    to {
        .ani-nft3-0;
    }
}
@keyframes nft4-0 {
    to {
        .ani-nft4-1;
    }
}
@keyframes nft4-1 {
    to {
        .ani-nft4-0;
    }
}
@keyframes nft5-0 {
    to {
        .ani-nft5-1;
    }
}
@keyframes nft5-1 {
    to {
        .ani-nft5-0;
    }
}
.hidden {
    display: none !important;
}
.mask-con {
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    top: 64px;
}

.mask-panel {
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 1);
    .item {
        display: flex;
        height: 64px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a {
            color: var(--Color-t1, #fafafa);
            text-align: right;
            // font-family: 'HarmonyOS Sans SC';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            text-decoration: none;
        }
    }
}
.connect-con {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    top: 0;
    .connect-pannel {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 360px;
        border-radius: 16px;
        border: 2px solid var(--White-100, #fff);
        background: var(--Black, #000);
        .connect-header {
            font-weight: 700;
            color: #fff;
            height: 60px;
            border-bottom: 1.5px solid var(--White-100, #fff);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            padding: 0 16px;
            svg {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            .connect-header-title {
                margin-left: 12px;
            }
            .connect-header-close {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .connect-item-con {
            padding: 16px;
        }
        .connect-item {
            cursor: pointer;
            width: 100%;
            text-align: left;
            padding: 14px;
            border-radius: 12px;
            border: 2px solid #fff;
            background: #22252d;
            margin-bottom: 16px;
            &:hover {
                border-color: #3671ee;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.3;
                border-color: #fff;
            }
            img {
                width: 44px;
                height: 44px;
                margin-right: 14px;
            }
        }
    }
}
.lg-screen {
    .lg-hide {
        display: none !important;
    }
}
.small-screen {
    .sm-hide {
        display: none !important;
    }
    .content {
        max-width: 100vw;
        padding-top: 20px;
    }
    .m-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        height: 64px;
        .m-header-left {
            display: flex;
            align-items: center;
            svg {
                width: 24px;
                height: 24px;
                &:active {
                    opacity: 0.75;
                }
            }
            .m-logo {
                width: 116px;
                height: 32px;
                margin-left: 16px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .m-header-right {
            display: flex;
            align-items: center;
            .twitler {
                margin-left: 16px;
            }
        }
    }
    .panel-one {
        width: 100%;
        height: unset;
        .title {
            width: 100%;
            margin-bottom: 0px;
        }
        .sub_title {
            font-size: 15px;
            margin-top: 24px;
            margin-bottom: 32px;
        }
        .ani-con {
            position: relative;
            height: 50vw;
        }
        .left-img-con {
            scale: 0.6;
            left: -45vw;
        }
        .right-img-con {
            scale: 0.6;
            right: -30vw;
        }
        .left-img {
            width: 100%;
            height: 100%;
        }
        .right-img {
            width: 100%;
            height: 100%;
        }
    }
    .panel-one-new {
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 30px;
        .left,
        .right {
            text-align: center;
            img {
                width: 80%;
                height: auto;
                // margin-left: 10%;
            }
        }
        .left {
            img {
                margin-bottom: 10px;
            }
            .label {
                font-size: 24px;
                line-height: 36px;
            }
        }
        .left {
            .group-btns {
                margin-top: 32px !important;
                justify-content: space-between;
                gap: 0px;
                .btn {
                    width: 50vw;
                    scale: 0.8;
                }
            }
        }
        .left {
            .guide-step-1 {
                position: inherit;
                width: calc(100vw - 40px);
                margin: 20px 20px 0 20px;
                min-width: unset;
                .guide-step-label {
                    text-align: left;
                }
            }
        }
    }
    .panel-four-2 {
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 24px;
        .line {
            border-right: 0;
            margin-right: 0;
            padding-right: 0;
            &::after {
                display: none;
            }
            text-align: center;
            img {
                width: 70%;
                height: auto;
            }
        }
        .btn-group {
            justify-content: center;
            flex-direction: column;
            gap: 0px;
            .btn {
                // scale: 0.8;
                margin-top: 16px;
            }
        }
    }
    .panel-four-3 {
        width: 100% !important;
        padding: 16px;
        box-sizing: border-box;
        background: transparent;
        border: none;
    }
    .panel-one-new,
    .panel-four-2,
    .panel-two,
    .panel-three,
    .panel-four,
    .panel-five,
    .panel-six {
        width: 100% !important;
        padding: 16px;
        box-sizing: border-box;
    }
    .panel-two {
        .pannel-two-item-title {
            font-size: 32px !important;
        }
        .pannel-two-item-desc {
            font-size: 12px !important;
        }
        .pannel-two-item-desc-1 {
            width: 100%;
            padding: 0 10px;
            margin-top: 10px;
        }
    }
    .panel-three {
        flex-wrap: wrap;
        flex-direction: column;
        &-img {
            width: 100% !important;
            height: auto !important;
        }
        &-right {
            width: 100% !important;
            padding-left: 16px;
        }
        &-sub-title {
            margin-top: 24px;
        }
        &-title {
            font-size: 24px;
            line-height: 40px;
            margin: 16px 0;
        }
        &-desc-1 {
            font-size: 20px;
            margin-bottom: 16px;
        }
        &-desc-2 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    .panel-four {
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 16px;
        &.panel-four-m-reverse {
            flex-direction: column-reverse;
        }
        &-left {
            width: 100% !important;
        }
        &-img {
            width: 100% !important;
            height: auto !important;
        }
        &-title {
            font-size: 24px;
            line-height: 40px;
            margin: 16px 0;
        }
        &-item {
            padding-left: 40px;
            margin-bottom: 16px;
            &::before {
                width: 24px;
                height: 24px;
                font-size: 12px;
                top: 6px;
            }
            i {
                left: 12px;
            }
            &-title {
                font-size: 20px;
                line-height: 24px;
            }
            &-desc {
                font-size: 16px;
                line-height: 20px;
            }
            &-2 {
                i {
                    height: 90px;
                }
            }
        }
    }
    .panel-five {
        margin-top: 16px;
        .panel-five-con {
            gap: 16px;
        }
        &-img-con {
            flex-wrap: wrap;
        }
        &-title {
            font-size: 24px;
        }
        &-sub {
            margin-top: 12px;
            font-size: 20px;
            margin-bottom: 8px;
        }
        &-img-con {
            // img {
            //     width: 40%;
            //     margin-bottom: 8px;
            // }
            // .img-con-icon {
            //     margin-left: 12px;
            //     margin-right: 12px;
            // }
        }
    }
    .panel-six {
        margin-top: 32px;
        margin-bottom: 12px;
        &-content {
            width: 100% !important;
            padding: 0;
            box-sizing: border-box;
            flex-direction: column;
            align-items: start;
            &-title {
                line-height: 32px;
                font-size: 24px;
                margin-bottom: 12px;
            }
            &-btn {
                height: 44px;
            }
        }
    }
    .border {
        margin: 24px 0;
    }
    .footer {
        flex-direction: column;
        padding: 40px;
        box-sizing: border-box;
        width: 100vw;
        box-sizing: border-box;
        .footer-1,
        .footer-2,
        .footer-3 {
            margin-bottom: 24px;
        }
    }
}
*::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: #000000;
}
*::-webkit-scrollbar-track {
    border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}

#groupfi_btn {
    background-image: var(--chatbox-image-dark) !important;
    &:hover {
        background-image: var(--chatbox-image-light-hover) !important;
    }
}
